.container {
  width: 100%;
}

.hide {
  display: none;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding-left: 18px;
  border-top: 1px solid #ddd;
  background: #f6f3eb;
  color: #000;
}

.back {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 18px !important;
  border-top: 1px solid #ddd;
}

.backIcon {
  height: 14px;
  color: #222;
  margin-right: 9px;
}

.backText {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
  position: relative;
  top: 1px;
  align-self: center;
}