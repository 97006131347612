.bannerContainer {
  width: 100%;
  background: #aabeb9;
  display: flex;
  align-items: center;
  padding: 0 9px;
  color: #222;
  position: relative;
  bottom: 0;
  min-height: 41px;
}

.button {
  border: none !important;
  border-radius: 33px !important;
  line-height: 27px !important;
  max-height: 26px;
  padding: 0 9px !important;
}

.closeIcon {
  color: #222;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  margin-right: 18px;
}

.description {
  flex-grow: 1;
  padding: 0 9px 0 0;
  line-height: 13px;
  overflow: hidden;
}

.appTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.appSubtitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
}

.appIconContainer {
  width: 30px;
  height: 30px;
  margin-right: 9px;
  background-color: #fff;
  border-radius: 8px;
  padding: 3px 3px 3px 6px;
}