/**
* Use repaint class for triggering repaint on top level wrapper.
* In safari there is a bug with `position:fixed` elements causing strange scroll behaviour on elements
* More information: https://bugs.webkit.org/show_bug.cgi?id=160953
**/
.repaint {
  width: 99.99% !important;
}

.topLevelWrapper {
  color: #222;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50px;
  position: fixed;
  overflow: scroll;
}
@media (max-width: 568px) {
  .topLevelWrapper {
    z-index: 10020;
  }
}
.topLevelWrapper.isBelowAppBanner {
  top: 94px;
}
.topLevelWrapper.isTransparent {
  background: transparent;
  overflow: hidden;
  margin-top: 70px;
  top: 0;
}
@media (min-width: 569px) {
  .topLevelWrapper.isTransparent {
    margin-top: calc(31px + 70px);
  }
}

.container {
  transition: transform ease-in-out 0.2s, visibility 0.2s;
  will-change: transform, visibility;
}

.leftEnter {
  transition: transform ease-in-out 0.2s, visibility 0.2s;
  will-change: transform, visibility;
  transform: translateX(-100%);
}

.rightEnter {
  transition: transform ease-in-out 0.2s, visibility 0.2s;
  will-change: transform, visibility;
  transform: translateX(100%);
}

.leftActiveExit {
  transform: translateX(-100%);
  visibility: hidden;
}

.rightActiveEnter,
.leftActiveEnter {
  transform: translateX(0);
}

.rightActiveExit {
  transform: translateX(100%);
  visibility: hidden;
}