.container {
  background-color: #fff;
  height: 54px;
  margin-bottom: 18px;
  padding: 0 18px;
  display: flex;
  position: relative;
}

@media (width >= 569px) {
  .container {
    margin-bottom: 0;
  }
}

.actionIconWrapper {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  padding: 0 !important;
}

.actionIcon {
  color: #000;
  width: 32px;
  height: 32px;
}

.actionIcon.closeIcon {
  color: #222;
  width: 22px;
  height: 22px;
}

.iconWrapper {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.icon {
  align-self: center;
  padding: 0 !important;
}

.messages {
  z-index: 10;
  background: #c00;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 11px;
  font-style: normal;
  display: flex;
  position: absolute;
  top: 7px;
  left: 33px;
}

.messagesCount {
  color: #fff;
  margin: auto;
}

.vipText {
  letter-spacing: 1px;
  width: 85px;
  margin: 4px 5px 0;
  font-size: 9px;
}

.logo {
  margin: auto;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
