/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
@keyframes fadeIn {
  0% {
    background: transparent;
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}
@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100%;
  }
  99% {
    background: transparent;
    height: 100vh;
    width: 100%;
  }
  100% {
    height: 0;
  }
}
.spacer {
  height: 70px;
}
@media (min-width: 1024px) {
  .spacer.hasMessageBanner {
    height: calc(70px + 31px);
  }
}
.spacer.isFullWidth {
  height: 118px;
}
.spacer.isFullWidth.isWithoutSearchBar {
  height: 50px;
}
.spacer.isFullWidth.isWithAppBanner {
  height: 159px;
}
.spacer.isFullWidth.isWithoutSearchBar.isWithAppBanner {
  height: 91px;
}

.container {
  font-size: 16px;
  font-weight: 300;
  z-index: 980;
  position: absolute;
  width: 100%;
  background-color: #fff;
  height: auto;
  line-height: 1;
  animation: none;
  will-change: transform;
}
.container.isFullWidth {
  position: fixed;
}
.container.disableStickyNav {
  position: absolute;
}
.container.isOpen {
  height: 100%;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}
.overlay.isOpen {
  animation: fadeIn 0.4s forwards;
  height: 100vh;
  width: 100%;
}
.overlay.isOpen.isFullWidth {
  animation: none;
}
.overlay.closeTransition {
  animation: fadeOut 0.4s forwards;
}
.overlay.closeTransition.isFullWidth {
  animation: none;
}

.animatedSearchBar {
  height: auto;
  left: 175px;
  padding: 0;
  position: absolute;
  top: 6px;
}

.isHidden {
  visibility: hidden;
}

.onlyShowSearchBar {
  height: 45px !important;
  background: #fff;
}

@media (max-width: 768px) {
  .whenSearchBarHidden {
    margin-bottom: 0;
  }
}