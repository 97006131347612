.bodyContainer {
  position: relative;
}
.bodyContainer:not(.isFullWidth) {
  background: #fff;
  height: 100%;
  overflow: scroll;
  width: 50%;
}

.bodyItem {
  position: absolute;
  width: 100%;
}