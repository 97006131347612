@keyframes fadeIn {
  0% {
    background: none;
  }

  100% {
    background: #000000b3;
  }
}

@keyframes fadeOut {
  0% {
    background: #000000b3;
    width: 100%;
    height: 100vh;
  }

  99% {
    background: none;
    width: 100%;
    height: 100vh;
  }

  100% {
    height: 0;
  }
}

.spacer {
  height: 70px;
}

@media (width >= 1024px) {
  .spacer.hasMessageBanner {
    height: 101px;
  }
}

.spacer.isFullWidth {
  height: 118px;
}

.spacer.isFullWidth.isWithoutSearchBar {
  height: 50px;
}

.spacer.isFullWidth.isWithAppBanner {
  height: 159px;
}

.spacer.isFullWidth.isWithoutSearchBar.isWithAppBanner {
  height: 91px;
}

.container {
  z-index: 980;
  will-change: transform;
  background-color: #fff;
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  animation: none;
  position: absolute;
}

.container.isFullWidth {
  position: fixed;
}

.container.disableStickyNav {
  position: absolute;
}

.container.isOpen {
  height: 100%;
}

.overlay {
  background: #000000b3;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.overlay.isOpen {
  width: 100%;
  height: 100vh;
  animation: .4s forwards fadeIn;
}

.overlay.isOpen.isFullWidth {
  animation: none;
}

.overlay.closeTransition {
  animation: .4s forwards fadeOut;
}

.overlay.closeTransition.isFullWidth {
  animation: none;
}

.animatedSearchBar {
  height: auto;
  padding: 0;
  position: absolute;
  top: 6px;
  left: 175px;
}

.isHidden {
  visibility: hidden;
}

.onlyShowSearchBar {
  background: #fff;
  height: 45px !important;
}

@media (width <= 768px) {
  .whenSearchBarHidden {
    margin-bottom: 0;
  }
}
