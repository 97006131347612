.item {
  height: 50px;
  line-height: 50px;
  padding-left: 18px;
}

.sectionTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  border-top: 1px solid #ddd;
  background: #f6f3eb;
  color: #000;
}

.sectionItem:not(:last-child) {
  border-bottom: 1px solid #ddd;
}