.container {
  width: 100%;
}

.hide {
  display: none;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  background: #f6f3eb;
  border-top: 1px solid #ddd;
  height: 50px;
  padding-left: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
}

.back {
  border-top: 1px solid #ddd;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  padding-left: 18px !important;
}

.backIcon {
  color: #222;
  height: 14px;
  margin-right: 9px;
}

.backText {
  color: #000;
  text-transform: uppercase;
  align-self: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  position: relative;
  top: 1px;
}
