.item {
  height: 50px;
  padding-left: 18px;
  line-height: 50px;
}

.sectionTitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  background: #f6f3eb;
  border-top: 1px solid #ddd;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.sectionItem:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
