.icon {
  vertical-align: middle;
  color: #000;
  width: 25px;
  height: 25px;
}

.favorite {
  width: 25px;
  height: 25px;
}

.container {
  position: relative;
  box-sizing: content-box;
  top: 2px;
  width: 25px;
  margin: auto 0 auto 18px;
  padding: 0;
  text-align: right;
}

.count {
  position: absolute;
  left: 14px;
  bottom: 12px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 10px;
  font-weight: 300;
  z-index: 10;
}

.maxCount {
  padding-left: 3px;
}

.bagIcon {
  position: relative;
  bottom: 2px;
}