.container {
  padding: 9px 18px;
}

.flexContainer {
  display: flex;
}

.button {
  flex: 1;
}

.button:not(:last-child) {
  margin-right: 25px;
}

.contactButton {
  margin-bottom: 9px;
}
