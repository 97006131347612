.wrapper {
  background: #fff;
  height: 50px;
  padding: 0 18px;
  position: relative;
}

@media (width >= 569px) {
  .wrapper {
    height: 64px;
    position: static;
    bottom: 16px;
    left: 50px;
  }

  .wrapper.expanded {
    width: 400px;
  }
}

.searchBar {
  width: 100%;
  transition: all .175s;
}

.suggestionsOverlay {
  height: calc(100vh - 100px);
  padding: 0 0 70px;
  overflow-y: scroll;
}

.suggestionsOverlay.belowAppBanner {
  padding-bottom: 140px;
}

.overlayWrapper {
  z-index: 10020;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 100px 0 0;
}

.overlayWrapper.belowAppBanner {
  top: 144px;
}

.whiteOverlay {
  opacity: 0;
  visibility: hidden;
  background: #fff;
  width: 100vw;
  height: 100vh;
  margin: 0;
  transition: all .175s;
  position: fixed;
  inset: 100px 0 0;
  overflow: hidden;
}

.whiteOverlay.belowAppBanner {
  top: 144px;
}

.whiteOverlay.visible {
  opacity: 1;
  visibility: visible;
}

.arrow {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 20px;
  left: 16px;
  right: 0;
  color: #fff !important;
}

.animatedSearchBar {
  width: 320px;
  height: 38px;
  padding: 16px;
  transition: width .4s;
  position: absolute;
  top: 0;
  right: 540px;
}

.animatedSearchBar.expanded {
  width: 400px;
}

.animatedSearchBar .overlayWrapper {
  top: 70px;
}

.animatedSearchBar .suggestionsOverlay {
  height: auto;
  max-height: none;
  padding-bottom: 0;
}

@media (width >= 569px) {
  .animatedSearchBar {
    top: auto;
    bottom: 32px;
  }

  .animatedSearchBar .overlayWrapper {
    top: 101px;
  }
}

@media (width <= 768px) {
  .onlySearchBarIsVisible {
    height: 61px;
    padding: 0 18px;
  }
}

.onlySearchBarIsVisible .searchBar {
  display: flex;
}

.onlySearchBarIsVisible .cancelButton {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #444;
  cursor: pointer;
  margin-left: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: color .2s;
  position: relative;
  top: 1px;
}

.onlySearchBarIsVisible .cancelButton:hover {
  color: #444;
  text-decoration: none;
}

.onlySearchBarIsVisible .overlayWrapper, .onlySearchBarIsVisible .whiteOverlay {
  top: 115px;
}

.onlySearchBarIsVisible .suggestionsOverlay {
  height: calc(100vh - 61px);
  box-shadow: none;
  border-top: none;
}
