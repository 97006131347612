.repaint {
  width: 99.99% !important;
}

.topLevelWrapper {
  color: #222;
  background: #fff;
  position: fixed;
  inset: 50px 0 0;
  overflow: scroll;
}

@media (width <= 568px) {
  .topLevelWrapper {
    z-index: 10020;
  }
}

.topLevelWrapper.isBelowAppBanner {
  top: 94px;
}

.topLevelWrapper.isTransparent {
  background: none;
  margin-top: 70px;
  top: 0;
  overflow: hidden;
}

@media (width >= 569px) {
  .topLevelWrapper.isTransparent {
    margin-top: 101px;
  }
}

.container {
  will-change: transform, visibility;
  transition: transform .2s ease-in-out, visibility .2s;
}

.leftEnter {
  will-change: transform, visibility;
  transition: transform .2s ease-in-out, visibility .2s;
  transform: translateX(-100%);
}

.rightEnter {
  will-change: transform, visibility;
  transition: transform .2s ease-in-out, visibility .2s;
  transform: translateX(100%);
}

.leftActiveExit {
  visibility: hidden;
  transform: translateX(-100%);
}

.rightActiveEnter, .leftActiveEnter {
  transform: translateX(0);
}

.rightActiveExit {
  visibility: hidden;
  transform: translateX(100%);
}
