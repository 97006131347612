.container {
  display: flex;
  height: 54px;
  background-color: #fff;
  margin-bottom: 18px;
  padding: 0 18px;
  position: relative;
}
@media (min-width: 569px) {
  .container {
    margin-bottom: 0;
  }
}

.actionIconWrapper {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  padding: 0 !important;
}

.actionIcon {
  color: #000;
  width: 32px;
  height: 32px;
}
.actionIcon.closeIcon {
  color: #222;
  width: 22px;
  height: 22px;
}

.iconWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.icon {
  align-self: center;
  padding: 0 !important;
}

.messages {
  position: absolute;
  display: flex;
  top: 7px;
  left: 33px;
  background: #cc0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-style: normal;
  font-size: 11px;
  z-index: 10;
}

.messagesCount {
  margin: auto;
  color: #fff;
}

.vipText {
  font-size: 9px;
  letter-spacing: 1px;
  width: 85px;
  margin: 4px 5px 0;
}

.logo {
  margin: auto;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}