.bodyContainer {
  position: relative;
}

.bodyContainer:not(.isFullWidth) {
  background: #fff;
  width: 50%;
  height: 100%;
  overflow: scroll;
}

.bodyItem {
  width: 100%;
  position: absolute;
}
